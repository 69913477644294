@keyframes dot1Anim {
    20% {
        transform: translate(4px, 0);
    }
    40% {
        transform: translate(4px, 0);
    }
    60% {
        transform: translate(4px, 0);
    }
    80% {
        transform: translate(4px, 4px);
    }
    100% {
        transform: translate(4px, 4px);
    }
}

@keyframes dot2Anim {
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, 4px);
    }
    80% {
        transform: translate(0, 4px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes dot3Anim {
    20% {
        transform: translate(-4px, 0);
    }
    40% {
        transform: translate(-4px, 0);
    }
    60% {
        transform: translate(-4px, 0);
    }
    80% {
        transform: translate(-4px, 4px);
    }
    100% {
        transform: translate(-4px, 4px);
    }
}

@keyframes dot4Anim {
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, -4px);
    }
    80% {
        transform: translate(0, -4px);
    }
    100% {
        transform: translate(0, -4px);
    }
}

@keyframes dot5Anim {
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, 0);
    }
    80% {
        transform: translate(0, 4px);
    }
    100% {
        transform: translate(0, 4px);
    }
}

@keyframes dot9Anim {
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(-4px, 0);
    }
    60% {
        transform: translate(-4px, -4px);
    }
    80% {
        transform: translate(-4px, -4px);
    }
    100% {
        transform: translate(-4px, -4px);
    }
}

@keyframes dot10Anim {
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(4px, 0);
    }
    60% {
        transform: translate(4px, -4px);
    }
    80% {
        transform: translate(4px, -4px);
    }
    100% {
        transform: translate(4px, -4px);
    }
}
