@import "loader-icon-animation.css";

@tailwind base;
@tailwind utilities;
@tailwind components;

.focus-classes {
    @apply focus-visible:ring focus-visible:ring-blue-focus focus-visible:ring-offset-2 focus-visible:ring-offset-white-base;
}

.ant-modal-root .ant-modal-mask {
    backdrop-filter: blur(45px);
}

/* Fix imported icons ui/src/modules/uikit/core/icons/SvgIcon */
.sol-antd-icon {
    [fill="#1A1A1A"] {
        fill: currentColor !important;
    }

    [fill-opacity="0.45"] {
        fill-opacity: 1 !important;
    }
}

/* DEPRECATED: Temporary fix for old icons */
.sol-icon:has([fill], [fill-rule], [stroke], [stroke-width]) {
    fill: none;

    [fill],
    [fill-rule] {
        fill: currentColor !important;
    }

    [stroke],
    [stroke-width] {
        stroke: currentColor !important;
    }
}

/* DEPRECATED: Temporary fix for old icons */
.sol-icon:not(:has([fill], [fill-rule], [stroke], [stroke-width])) {
    fill: currentColor;
}

.ant-btn .ant-btn-icon > svg {
    display: flex;
}

/* ANT DESIGN: Descriptions */
.ant-descriptions .ant-descriptions-item-label {
    font-weight: var(--ant-font-weight-strong);
}

.ant-descriptions .ant-descriptions-item-label::after {
    content: ''
}

.ant-descriptions:not(.ant-descriptions-bordered) .ant-descriptions-row:not(:last-child):has(> * .ant-descriptions-item-content) {
    border-bottom: var(--ant-line-width) solid var(--ant-color-border);
    --ant-descriptions-item-padding-bottom: var(--ant-padding-lg);
}

.ant-descriptions:not(.ant-descriptions-bordered) .ant-descriptions-row:not(:first-child):has(> * .ant-descriptions-item-label) .ant-descriptions-item {
    padding-top: var(--ant-padding-lg);
}

.ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view .ant-descriptions-row > .ant-descriptions-item-label {
    color: var(--ant-color-text);
}
